<template lang="html">

  <section class="panel-gestion vacantes" v-if="Object.keys(store.offers).length != 0">

    <p class="title-line">Vacantes</p>
    <!-- <p class="subtitle-big">
      Ofertas
    </p> -->
    <!-- Ofertas -->
    <div class="container-offers grid-3" :showOffer="showOffer">
      <div class="grid" v-for="(offer, key ) in store.offers" :key="key">
       <div class="offers-data">
        <p class="title">{{offer.name}}</p>
        <p class="description">{{offer.header}}</p>
       </div>
        <button @click="showOfferInfo(offer.slug)" class="btn-blue-border">
          Más información
        </button>
      </div>
    </div>
    <div class="info-offer" :showOffer="showOffer" v-if="offerSelected">

      <div class="content">
        <p class="subtitle-big">{{offerSelected.name}}</p>
        <p>
          {{offerSelected.header}}
        </p>
        <div class="d-flex">
          <div class="location">
            <p class="destacado">Localización</p>
            <p>{{offerSelected.place}}</p>
          </div>
          <div class="date">
            <p class="destacado">Fecha publicación &nbsp;</p>
            <p>{{currentDateTime(offerSelected.publish_date)}}</p>
          </div>
        </div>
        <p class="destacado">Descripción</p>
        <EditorJs :object="offerSelected.body" />

      </div>
      <div class="apply-offer" v-if="offerSelected">
        <p class="subtitle-big">Aplicar oferta</p>
        <p>Rellena el formulario de inscripción para apuntarte a la oferta.</p>
        <div class="form-contact">
          <section class="container-success" v-if="storeForm.alert.active">
            <div class="container-text">
              <p class="title">
                {{storeForm.alert.text}}
              </p>
              <button class="btn-blue" @click="cleanForm()">Aceptar</button>
            </div>
          </section>
          <section class="container-success" v-if="storeForm.sending">
            <div class="container-text gif">
              <Vue3Lottie :animationData="loadingAnimation" :height="auto" width="100%" />
            </div>
          </section>
          <Form id="formOffer">
            <div class="column">
              <label for="name">Nombre y Apellidos*<br>
                <span class="mss-error" v-if="v$.name.$error">
                  {{ v$.name.$errors[0].$message }}
                </span>
              </label>
              <input id="name" name="name" type="text" placeholder="Nombre Apellido Apellido" v-model="state.name"
                :class="[v$.name.$error ? 'class-error' : '']">
            </div>
            <input type="text" :value="offerSelected.id" id="offer_id" name="offer_id" hidden>
            <div class="column">
              <label for="deparment">Departamento Actual*<br>
                <span class="mss-error" v-if="v$.deparment.$error">
                  {{ v$.deparment.$errors[0].$message }}
                </span>
              </label>
              <input name="deparment" id="deparment" type="text" placeholder="Departamento" v-model="state.deparment"
                :class="[v$.deparment.$error ? 'class-error' : '']">
            </div>
            <div class="column">
              <label for="email">Email*<br>
                <span class="mss-error" v-if="v$.email.$error">
                  {{ v$.email.$errors[0].$message }}
                </span>
              </label>
              <input name="email" id="email" type="text" placeholder="email@email.com" v-model="state.email"
                :class="[v$.email.$error ? 'class-error' : '']">
            </div>
            <div class="column">
              <label for="motivation_card">Anexar carta motivación*<br>
                <span class="mss-error" v-if="msg_file_motivation_card">
                  Este campo es obligatorio
                </span>
              </label>
              <input type="file" name="motivation_card" id="motivation_card" placeholder="Escribe aquí...."
                :class="[msg_file_motivation_card ? 'class-error' : 'input-styled message']">
            </div>
            <div class="column">
              <label for="recomend_card">Anexar carta recomendación (opcional)
              </label>
              <input type="file" name="recomend_card" id="recomend_card" placeholder="Escribe aquí...."
                class="input-styled message">
            </div>
            <div class="column">
              <label for="cv">Anexar curriculum*<br>
                <span class="mss-error" v-if="msg_file_cv">
                  Este campo es obligatorio
                </span>
              </label>
              <input type="file" id="cv" name="cv" placeholder="Escribe aquí...."
                :class="[msg_file_cv ? 'class-error' : 'input-styled message']">

              <div class="checkbox-styled w-100">
                <input name="rgpd" id="rgpd" type="checkbox" v-model="state.rgpd">
                <label for="rgpd">
                  He leido y acepto la
                  <a href="/politica-de-privacidad" target="_blank" title="Política de privacidad">Política de Privacidad</a>.<br>
                  <span class="mss-error" v-if="v$.rgpd.$error">
                    {{ v$.rgpd.$errors[0].$message }}
                  </span>
                </label>
              </div>
            </div>

            <div class="row-center w-100">
              <span class="btn-blue" @click="submitForm()">Enviar</span>
            </div>
          </Form>
        </div>
      </div>
      <div class="row-center">
        <button @click="goListOffer" class="btn-blue-border">Volver al listado de ofertas</button>
      </div>
    </div>
  </section>


</template>

<script lang="js">
  import { contentStore } from '@/stores/contents'
  import { formStore } from '@/stores/forms'
  import { Vue3Lottie } from 'vue3-lottie'
  import 'vue3-lottie/dist/style.css'
  import loadingAnimation from '@/lotties/loading.json'
  import moment from 'moment'
  import EditorJs from "@/components/editorJs.vue";
  import useValidate from "@vuelidate/core";
  import { reactive, computed } from "vue";
  import { required, email, helpers } from "@vuelidate/validators";
  const requiredMsg = helpers.withMessage('Este campo es obligatorio', required);
  const store = contentStore();
  const storeForm = formStore();
  export default {
    name: 'panel-offers',
    props: [],
    components: {
      EditorJs,
      Vue3Lottie
    },
    setup() {
      const state = reactive({
        name: '',
        email: '',
        offer_id: '',
        deparment: '',
        motivation_card: '',
        recomend_card: '',
        cv: '',
        rgpd: ''
      })
      const rules = computed(() => {
        return {

          name: {
            required: requiredMsg,
          },
          deparment: {
            required: requiredMsg,
          },
          email: {
            required: requiredMsg,
            email: helpers.withMessage('Por favor introduzca un email válido', email)
          },
          rgpd: {
            required: requiredMsg,
          },

        }
      })

      const v$ = useValidate(rules, state)

      return {
        state,
        v$,
        store,
        storeForm,
      }
    },
    mounted() {
      store.loadOffers();
    },
    data() {

      return {
        loadingAnimation,
        showOffer: false,
        offerSelected: null,
        msg_file_motivation_card: false,
        msg_file_cv: false
      }
    },
    methods: {
      showOfferInfo: function (data) {
        this.offerSelected = store.offers[data],
          this.showOffer = true;
      },

      currentDateTime(date) {
        return moment(date).locale('es').format('L')
      },
      validateCV() {
        var inp = document.getElementById('cv');
        if (inp.files.length == 0) {
          this.msg_file_cv = true
        }
        else {
          this.msg_file_cv = false
        }
        return this.msg_file_cv;
      },
      validateMotivationCard() {
        var inp = document.getElementById('motivation_card');
        if (inp.files.length == 0) {
          this.msg_file_motivation_card = true
        }
        else {
          this.msg_file_motivation_card = false
        }
        return this.msg_file_motivation_card;
      },
      cleanForm() {
        storeForm.alert.active = false;
        storeForm.alert.text = '';
        // this.$router.go()
      },
      goListOffer: function () {
        this.showOffer = false;
        this.state = {
          name: '',
          email: '',
          offer_id: '',
          deparment: '',
          motivation_card: '',
          recomend_card: '',
          cv: '',
          rgpd: ''
        }
      },
      async submitForm() {
        this.validateCV()
        this.validateMotivationCard()

        var isFormCorrect = await this.v$.$validate()
        if (isFormCorrect) {
          var formElement = document.getElementById("formOffer");
          var formData = new FormData(formElement);
          storeForm.formOffer(formData).then((response) => {
            if (response.status == 200) {
              storeForm.alert.active = true;
              storeForm.alert.text = 'El formulario se ha mandado con éxito.';
              this.state = {
                name: '',
                email: '',
                offer_id: '',
                deparment: '',
                motivation_card: '',
                recomend_card: '',
                cv: '',
                rgpd: ''
              }
            }
          })
        }

      }

    },
    computed: {

    }
  }


</script>

<style scoped lang="scss">
  .mss-error {
    color: orange;
    font-weight: 400;
    font-size: 16px;
    margin-top: 5px;
    display: inline-block;
  }

  .class-error {
    border: 1px solid orange;
  }

  input.class-error[type="file"] {
    border: 0;
  }

  input.class-error::-webkit-file-upload-button {
    border: 1px solid orange !important;
    background: orange !important;
    color: white !important;
  }
</style>