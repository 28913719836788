<template lang="html">
  <section class="panel-gestion" v-if="dataGestion" :class="dataGestion.name">
    <p class="title-line">{{ dataGestion.name }}</p>

    <!-- MENU GENERAL -->
    <div class="active content-panel-section">
      <p class="title">{{ dataGestion.subtitle }}</p>
      <!-- <p class="subtitle">{{ dataGestion.subtitle }}</p> -->
      <EditorJs :object="dataGestion.body" />
    </div>

    <!-- MENU APLICACIONES -->
    <div class="active content-panel-section"
      v-if="Object.keys(dataGestion.applications).length != 0">
      <p class="title-panel">Solicitudes</p>
      <ul class="menu-dropdown-panel">
        <template v-for="(aplication, key) in dataGestion.applications" :key="key">
          <li>
<!--             <input type="checkbox" name="list" :id="aplication.id" />
            <label :for="aplication.id">{{ aplication.name }}</label> -->
            <ul class="inner-container">
              <p class="subtitle">{{ aplication.description }}</p>
              <EditorJs :object="aplication.body" />
              <template v-for="(file, key) in aplication.files" :key="key">
                <div class="item">
                  <a target="_blank" :href="file.file[0] ? file.file_url : file.file[0].url" :title="file.name"
                    class="link-blue">{{file.name}}</a>
                  <a class="link-blue" v-if="file.file[0].url" target="_blank" :href="file.file[0].url" title="descargar archivo"
                    download>Descargar archivo</a>
                </div>
              </template>
            </ul>
          </li>
        </template>
      </ul>
    </div>

    <!-- MENU DOCUMNETOS -->
    <div class="active content-panel-section"
      v-if="Object.keys(dataGestion.files).length != 0">
      <p class="title-panel">Documentos / Normativas</p>
      <ul class="menu-dropdown-panel">
        <template v-for="(items, type) in dataGestion.files" :key="type">
<!--           <input type="checkbox" name="list" :id="type" />
          <label :for="type" :class="{ 'dot': dataGestion.dotsEnabled.filesType.includes(parseInt(type)), 'title': 1 }">
            <span>{{ $t('gestions.files_types_values.' + type) }}</span>
          </label> -->
          <ul class="inner-container">
            <div class="container-doc-link">
              <template v-for="(file, key) in items" :key="key">
                <div class="item">
                  <div class="content">
                    <p class="title -new" :data-new="file.new" :data-updated="file.updated">{{ file.name }}</p>
                    <p class="subtitle">{{ file.description }}</p>
                  </div>
                  <div class="cont-btn">
                    <a v-if="file.file[0].url" target="_blank" :href="file.file[0].url" download title="descargar"
                      class="btn-blue-border">Descargar</a>
                    <a v-if="file.file_url" target="_blank" :href="file.file_url" class="btn-blue-border" title="Ir a la página">Ir a la
                      página</a>
                  </div>


                </div>
              </template>
            </div>
          </ul>
        </template>
      </ul>
    </div>
  </section>
</template>

<script lang="js">
  import EditorJs from "@/components/editorJs.vue";
  export default {
    name: 'panel-gestion',
    props: ['dataGestion', 'slug'],
    components: {
      EditorJs
    },
    data() {
      return {
        menuSelected: 'gestions'
      }
    },
    methods: {
      openSubPanel: function (tab) {
        this.menuSelected = tab;
      }
    },
    watch: {
      dataGestion() {
        this.menuSelected = 'gestions';
      },
    },
  }

</script>