<template lang="html">
  <section class="gestion-page">
    <template v-if="deparment">
      <bannerHead :dataTitle="deparment.name" :dataDescription="deparment.description"></bannerHead>
      <div class="box-filters container">
        <breacrumbs :data-parent="deparment.slug == 'politicas-y-protocolos' ? '' : 'Mis gestiones'" :dataTitle="deparment.name"></breacrumbs>
        <div class="filters" v-if="slug != 'seleccion-y-talento'">
          <div class="box-tool-search">
            <label for="searchTool" hidden>Buscar</label>
            <input class="input-styled" name="buscar" type="text" placeholder="Buscar" id="searchTool" v-model="textSearch" />
            <button class="btn-search" @click="searchText" title="Buscar"></button>
            <button v-if="removeSearch" class="btn-clean" title="Limpiar búsqueda" @click="cleanText"></button>
          </div>
        </div>
      </div>
    </template>

    <div class="box-tool-search container">
      <button class="btn-seeall" @click="showHide" :showmenu="show">Ver todos</button>
    </div>
    <section class="container-success" v-if="store.loading">
      <div class="container-text gif">
        <Vue3Lottie :animationData="loadingAnimation" :height="auto" width="100%" />
      </div>
    </section>
    <section class="full-content container gestion" :gestion="slugGestion"  id="menu" v-if="categories && Object.keys(categories).length > 0 ">
      <div class="container" :showmenu="show">
        <ul class="menu-dropdown">
          <router-link class="item-content -one" style="background:white" :class="{ 'active-item': gestionSelected == null && slugGestion != 'movilidad-activa'}" v-if="textSearch == null" :to="{
              name: 'gestion-page',
              params: {
                slug: this.slug,
              }
            }">
            <span v-if="deparment">{{deparment.name}}</span>
          </router-link>


          <template v-for="(category, key) in categories" :key="key">
            <li v-if="category.items">
              <input type="checkbox" name="list" :id="category.slug" :checked="getIfCategorySelect(category)" />
              <label :class="{ 'dot': category.dotEnabled }" :for="category.slug">
                <span>{{category.name}} </span>
              </label>
              <ul class="inner-container">
                <template v-for="(gestion, key ) in category.items" :key="key">
                  <router-link class="item-content" :to="{
                      name: 'gestion-page',
                      params: {
                        slug: this.slug,
                        slugGestion: gestion.slug,
                      }
                    }" :class="{ 'active-item': gestionSelected && gestion.slug == gestionSelected.slug, 'dot': gestion.dotsEnabled.files || gestion.dotsEnabled.applications }">
                    <span>{{ gestion.name }}</span>
                  </router-link>
                </template>
              </ul>
            </li>
            <template v-else>
              <!-- {{category}} -->
              <router-link class="item-content -one" :to="{
                  name: 'gestion-page',
                  params: {
                    slug: this.slug,
                    slugGestion: category.slug,
                  }
                }" :class="{ 'active-item ': gestionSelected && category.slug == gestionSelected.slug, 'dot': category.dotsEnabled.files || category.dotsEnabled.applications }">
                <span class="not-category">{{ category.name }}</span>
              </router-link>

            </template>
          </template>
          <router-link v-if="deparment.id == 2" class="item-content -one" :class="{ 'active-item': slugGestion == 'movilidad-activa'}" :to="{
                        name: 'gestion-page',
                        params: {
                          slug: this.slug,
                           slugGestion:'movilidad-activa',
                        }
                      }">
            <span>Vacantes</span>
          </router-link>
        </ul>

        <div class="content-info active" >
          <div v-if="infoDepartament" class="content-depart">
            <template v-if="deparment.body != 'null'">
              <div class="d-flex">
                <p class="title-line" v-if="deparment">{{deparment.name}}</p>
              </div>
              <EditorJs :object="deparment.body" />
            </template>
          </div>
          <template v-else>
            <div v-if="departmentMovilidadInterna && slugGestion == 'movilidad-activa'" class="content-depart">
              <template v-if="departmentMovilidadInterna.body != 'null'">
                <div class="d-flex">
                  <p class="title-line" v-if="deparment">{{departmentMovilidadInterna.name}}</p>
                </div>
                <EditorJs :object="departmentMovilidadInterna.body" />
              </template>
            </div>
           
            <panelGestion :dataGestion="gestionSelected" ></panelGestion>
          </template>

        </div>

      </div>
      <panelOffers></panelOffers>
    </section>
    <template v-else>
      <div class="container container-result">
        <p class="title-line -center">No hay resultados</p>
      </div>
    </template>


  </section>
</template>

<script lang="js">
  import { contentStore } from '@/stores/contents'
  import bannerHead from '@/components/banner-head.vue'
  import breacrumbs from '@/components/breacrumbs.vue'
  import panelGestion from '@/components/panel-gestion.vue'
  import { Vue3Lottie } from 'vue3-lottie'
  import 'vue3-lottie/dist/style.css'
  import loadingAnimation from '@/lotties/loading.json'
  import panelOffers from '@/components/panel-offers.vue'
  import EditorJs from "@/components/editorJs.vue";
  const store = contentStore();
  export default {
    name: 'gestion-page',
    props: ['slug', 'slugGestion'],
    components: {
      bannerHead,
      breacrumbs,
      panelGestion,
      panelOffers,
      EditorJs,
      Vue3Lottie
    },
    setup() {
      return {
        getDepartamentBySlug: store.getDepartamentBySlug,
        getGestionsByDepartment: store.getGestionsByDepartment,
        getCategoriesByDepartment: store.getCategoriesByDepartment,
        store
      }
    },
    el: '#menu',
    data() {
      return {
        loadingAnimation,
        gestionSelected: null,
        textSearch: null,
        removeSearch: false,
        show: false,
      }
    },
    mounted() {
      this.loadNewDeparment();
    },
    computed: {
      deparment() {
        return this.getDepartamentBySlug(this.slug);
      },
      gestions() {
        return this.getGestionsByDepartment(this.slug);
      },
      categories() {
        return this.getCategoriesByDepartment(this.slug);
      },
      departmentMovilidadInterna() {
        let movilidadMenu = null
        Object.values(this.store.departments).forEach(d => {
          if (d.position == 0) {
            movilidadMenu = d
          }
        })
        return movilidadMenu
      },
    },
    methods: {
      showHide() {
        this.show = !this.show;
      },
      getContentPanel(data) {
        if (data) {
          this.gestionSelected = store.gestions[this.slug].items[data];
        } else {
          this.gestionSelected = null;
        }
      },
      getIfCategorySelect(category) {
        let bool = false;
        if (this.gestionSelected) {
          this.gestionSelected.categories.every(function (ca) {
            if (ca.slug == category.slug) {
              bool = true;
            }
            return !bool;
          });
        }
        return bool;
      },
      searchText() {
        this.loadNewDeparment()
        this.removeSearch = true;
      },
      cleanText() {
        this.textSearch = null;
        this.removeSearch = false;
        this.loadNewDeparment();
      },
      loadNewDeparment() {
        let t = this;
        t.gestionSelected = null;
        store.loading = true;
        let promise = store.loadGestions(
          {
            slug: this.slug,
            textSearch: this.textSearch
          }
        );
        promise.then(function () {
          store.loading = false;
          t.autoSelect();
        });
      },
      autoSelect() {
        this.gestionSelected = null;
        this.infoDepartament = true;
        if (this.slugGestion) {
          this.infoDepartament = false;
          if (this.categories == null || Object.keys(this.categories).length > 0) {
            let gestionSlug = this.slugGestion ? this.slugGestion : Object.keys(this.gestions)[0];
            this.gestionSelected = this.gestions[gestionSlug];
          }
        }
      }
    },
    watch: {
      slug() {
        this.textSearch = null;
        this.loadNewDeparment();
      },
      slugGestion() {
        this.autoSelect();
        this.show = false
      },
    },
  }


</script>

<style scoped lang="scss">
  .content-depart .title-line {
    font-size: 24px;
    margin-bottom: 20px;
    margin-left: -10px;
  }
</style>